import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import BookingForm2 from '../components/FormBuchung2.jsx';


const Page_3= ()=>  {

    
  return (
    <div>
    
            <Navbar />
            <div className='max-w-xl m-4 md:max-w-7xl md:mx-auto md:pt-20 pb-32'>
                <BookingForm2 />
                
            </div>
            <Footer />
           
    </div>
  );
}

export default Page_3;
