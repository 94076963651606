import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Kurs_2 from '../components/kurs_2';


const Page_5= ()=>  {

    
  return (
    <div>
    
            <Navbar />
            <div className='max-w-xl m-4 md:max-w-7xl md:mx-auto  pb-32'>
                <Kurs_2 />
                
            </div>
            <Footer />
           
    </div>
  );
}

export default Page_5;
