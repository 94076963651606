import React, { useState } from "react";
import data from '../data';

const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={`faq ${faq.open ? "open" : ""} bg-white p-4 rounded-md shadow-md mb-6 cursor-pointer`}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question text-lg p-4 pb-0 pt-0 pl-2 relative">
        {faq.question}
        <span
          className="absolute pb-8 top-1/2 -right-2 transform -translate-y-1/2 w-8 h-8 flex justify-center items-center text-2xl transition duration-200 ease-in-out cursor-pointer"
          style={{ transform: faq.open ? "rotate(-0deg)" : "rotate(0deg)" }}
        >
          {faq.open ? "-" : "+"}
        </span>
      </div>
      <div
        className={`faq-answer overflow-hidden pt-2 text-gray-400 transition-all duration-200 ease-in-out ${
          faq.open ? "max-h-full opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        {faq.answer}
      </div>
    </div>
  );
};

const FAQState = () => {
  const { faqData } = data;

  const [faqs, setFaqs] = useState(faqData);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        faq.open = i === index ? !faq.open : false;
        return faq;
      })
    );
  };

  return (
    <div id="FAQ" className="bg-[#edede9] pb-10 mt-8 pt-[1px] max-w-screen"> 
 
      <section id='testimonials' className="">
        {/* Container to heading and testm blocks */}
        <div className='max-w-6xl px-5 mx-auto mt-28 text-center'>
          {/* Heading */}
          <p className="text-blue-400 text-lg">FAQ</p>

          <h2 className='text-4xl font-bold text-center'>
              FAQs KinderCampus
          </h2>
          <p className="mx-auto max-w-lg text-gray-500 text-center pt-6 font-light ">
          Hier finden Sie Antworten zu den häufigsten Fragen im Rahmen des KinderCampus-Projektes. 
          </p>
        </div>
      </section>

      <div className="w-full max-w-screen-md mx-auto -px-2 p-4  pt-10">
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
};

export default FAQState;
