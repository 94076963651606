import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from 'emailjs-com';

// EmailJS credentials
const EMAILJS_SERVICE_ID = 'service_jifycl6'; // Ersetzt durch deine Service ID
const EMAILJS_TEMPLATE_ID = 'template_zbvl5yj'; // Ersetzt durch deine Template ID
const EMAILJS_USER_ID = '8B3Sn8tVh2AoJLuVj'; // Ersetzt durch deinen User ID (Public Key)

const BookingForm2 = () => {
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
  const [formData, setFormData] = useState({
    course: '',
    guardianName: '',
    childName: '',
    email: '',
    message: ''
  });

  const [captchaToken, setCaptchaToken] = useState(null); // State to hold the reCAPTCHA token
  const [errorMessage, setErrorMessage] = useState(''); // State to hold error messages

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    setErrorMessage('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setErrorMessage('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
      return;
    }

    if (!formData.course) {
      setErrorMessage('Bitte wählen Sie einen Kurs aus.');
      return;
    }

    try {
      const templateParams = {
        course: formData.course,
        guardianName: formData.guardianName,
        childName: formData.childName,
        email: formData.email,
        message: formData.message
      };

      const response = await emailjs.send(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        templateParams,
        EMAILJS_USER_ID
      );

      console.log('Email Response:', response);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error sending email:', error);
      setErrorMessage(`Fehler beim Versenden der E-Mail: ${error.message}`);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        window.location.href = '/'; // Redirect to the home page after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  if (isSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center mt-20 md:-mt-40">
        <div className="bg-white p-10 rounded-md shadow-md mx-auto text-center">
          <div className="rounded-full h-52 w-52 md:h-[140px] md:w-[140px] bg-[#F8FAF5] flex items-center justify-center mx-auto mb-6">
            <i className="checkmark text-[#9ABC66] text-[100px] md:text-[100px] leading-[200px]">✓</i>
          </div>
          <h1 className="text-[#88B04B] font-nunito font-extrabold text-[18px] md:text-[24px] mb-2">Sie haben unseren Kurs erfolgreich angefragt</h1>
          <p className="text-[#404F5E] font-nunito text-[14px] m-0">
            In Kürze erhalten Sie eine E-Mail mit Informationen bezüglich der Aufnahme und ggf. eine Bestätigung zur erfolgreichen Anmeldung. <br /> Vielen Dank für Ihr Interesse.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 pt-28 md:grid-cols-2 gap-8">
      <div>
        <h2 className="text-2xl font-semibold mb-4">Jetzt Platz anfragen</h2>
        <p className="mb-4 md:max-w-md">
          Fragen Sie gerne über unser Formular einen Platz für den gewünschten Kurs und Zeitraum an. Wir werden uns sobald wie möglich bei Ihnen melden und Ihnen mitteilen, ob Sie den Platz erhalten haben. Nach erfolgreichem Absenden Ihrer Nachricht erhalten Sie eine Eingangsbestätigung an Ihre angegebene E-Mail-Adresse. Schauen Sie ggf. auch in Ihren Spam-Ordner.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md space-y-4">
        <div>
          <label htmlFor="course" className="block font-semibold">Kurs auswählen *</label>
          <select id="course" name="course" value={formData.course} onChange={handleChange} className="border-2 w-full h-10 bg-gray-200 py-2 px-4 rounded-[2%]" required>
            <option value="">Wählen Sie einen Kurs aus</option>
            <option value="04.08.2025 - 08.08.2025: Expertenkurs (Python)">04.08.2025 - 08.08.2025: Expertenkurs (Python)</option>
            <option value="11.08.2025 - 15.08.2025: Expertenkurs (Python)">11.08.2025 - 15.08.2025: Expertenkurs (Python)</option>
          </select>
        </div>
        <div>
          <label htmlFor="guardianName" className="block font-semibold">Name des Erziehungsberechtigten *</label>
          <input type="text" id="guardianName" name="guardianName" value={formData.guardianName} onChange={handleChange} className="w-full border-gray-200 border-2 rounded-md p-2" required />
        </div>
        <div>
          <label htmlFor="childName" className="block font-semibold">Name des Kindes *</label>
          <input type="text" id="childName" name="childName" value={formData.childName} onChange={handleChange} className="w-full border-gray-200 border-2 rounded-md p-2" required />
        </div>
        <div>
          <label htmlFor="email" className="block font-semibold">E-Mail *</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="w-full border-gray-200 border-2 rounded-md p-2" required />
        </div>
        <div>
          <label htmlFor="message" className="block font-semibold">Nachricht</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} rows="4" className="w-full border-gray-200 border-2 rounded-md p-2"></textarea>
        </div>
        <div>
          <ReCAPTCHA
            sitekey="6Ldt6ycqAAAAAKnQaYH2k2Efr3yaKPLN-OuxJEpK" 
            onChange={handleCaptchaChange}
          />
        </div>
        {errorMessage && (
          <div className="text-red-500 font-semibold">{errorMessage}</div>
        )}
        <div className="flex items-center space-x-2">
          <input type="checkbox" id="agreement" name="agreement" required />
          <label htmlFor="agreement" className="text-sm">Sie erklären sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und Widerrufshinweise finden Sie in unserer <a href='' className='text-blue-400'>Datenschutzerklärung</a> .</label>
        </div>
        <button type="submit" className="bg-[#003566] text-white py-2 px-4 rounded-md transition-colors">Absenden</button>
      </form>
    </div>
  );
};

export default BookingForm2;
