import kurs_1 from '../assets/images/bsp1.webp';
import { PiStudentThin } from "react-icons/pi";
import { TbMessageLanguage } from "react-icons/tb";
import { IoTimeOutline } from "react-icons/io5";
import { SiSession } from "react-icons/si";
import { HiMiniComputerDesktop } from "react-icons/hi2";
import { MdOutlineStackedBarChart } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import { IoCalendarOutline } from "react-icons/io5";
import { courseDates } from '../data'; // Import courseDates from data.js

const Kurs_1 = () => {
  const [selectedCourse, setSelectedCourse] = useState('');
  const navigate = useNavigate();

  const handleBooking = () => {
    navigate(`/buchung/`);
  };

  return (
    <div>
      <div className="max-w-7xl pt-24 md:pt-32 xl:mx-auto md:mx-16 flex flex-col md:flex-row">
        <div className="flex-2 m-2 md:m-4">
          <img
            src={kurs_1}
            alt="Course"
            className="w-full h-auto md:w-full md:h-auto border-solid border-2 border-gray-500 rounded-xl z-[100] drop-shadow-2xl"
          />
          <div className="p-4 md:p-8">
            <h2 className="text-3xl md:text-5xl pt-2 font-normal">Grundkurs mit Scratch</h2>
            <p className="mt-2 pt-4 text-gray-500 text-light">
               Im Rahmen des Grundkurses wird die Programmiersprache Scratch verwendet. Es werden keine spezifischen Programmierkenntnisse vorausgesetzt. Durch die Gestaltung von ansprechenden Modellen 
               und die Programmierung von Bewegungen und Funktionen erhalten die Teilnehmer einen spielerischen Einblick in die Welt der Informatik.
            </p>
            <p className="mt-2 hidden md:block text-gray-500 text-light">
              Ein besonderes Merkmal des KinderCampus ist die persönliche Betreuung durch Studierende, die den Teilnehmern dabei helfen, ihre Fähigkeiten weiterzuentwickeln und individuelle 
              Herausforderungen zu meistern. Diese enge Interaktion ermöglicht es den Teilnehmern, in ihrem eigenen Tempo zu lernen und sich in einer unterstützenden Umgebung wohl zu fühlen.
            </p>
            <p className="mt-2 hidden md:block text-gray-500 text-light">
              Die Teilnahme am KinderCampus bietet nicht nur die Möglichkeit technische Fähigkeiten zu erlernen, sondern auch wichtige Fähigkeiten wie Problemlösung, Kreativität und Teamarbeit zu entwickeln. 
            </p>
          </div>
        </div>

        <div className="flex-1 p-4 bg-white rounded-xl md:h-1/2		 mt-6 border-2 md:m-4">
          <div className="rounded-md p-6 ">
            <div className="text-4xl font-normal text-gray-700 mb-4">Grundkurs</div>
            <div className='border-solid border-2 border-gray-200 rounded-xl p-4 mb-4'>
              <div className="flex justify-between text-sm">
                <div className='flex flex-row'>
                  <HiMiniComputerDesktop size={20} className='mt-1' />
                  <div className='flex flex-col ml-2'>
                    <h4 className='text-gray-400 font-light text-[10px]'>Kursdauer</h4>
                    <div className='text-gray-800 font-bold text-[14px]'>5 Tage (30 Std.)</div>
                  </div>
                </div>
                <hr className='w-14 h-2 rotate-90 pt-10 mr-2' />
                <div className='flex flex-row'>
                  <MdOutlineStackedBarChart size={20} className='mt-1' />
                  <div className='flex flex-col ml-2'>
                    <h3 className='text-gray-400 font-light text-[12px]'>Schwierigkeit</h3>
                    <div className='text-gray-800 font-bold text-[14px]'>Grundkurs</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-sm space-y-2 mb-4">
              <div className='flex flex-row space-x-2'>
                <PiStudentThin size={24} />
                <p className='text-gray-500 font-normal'>Teilnehmer: </p>
                <p className='text-gray-800 font-medium'>10-14 Jahre</p>
              </div>
              <div className='flex flex-row space-x-2'>
                <TbMessageLanguage size={24} />
                <p className='text-gray-500 font-normal'>Kosten: </p>
                <p className='text-gray-800 font-medium'>200 Euro</p>
              </div>
            <div className='flex flex-row space-x-2'>
              <IoTimeOutline size={24} />
              <p className='text-gray-500 font-normal'>Dauer: </p>
              <p className='text-gray-800 font-medium'>Montag - Freitag</p>
            </div>
            <div className='flex flex-row space-x-2'>
              <IoTimeOutline size={22} />
              <p className='text-gray-500 font-normal'>Zeit: </p>
              <p className='text-gray-800 font-medium'>10:00 Uhr - 16:00 Uhr</p>
            </div>
              <div className='space-y-2'>
                <div className="flex flex-row items-center">
                  <IoCalendarOutline size={24} className='text-black' />
                  <p className='pl-2 text-gray-800 font-light'>{courseDates[0].kurs1}</p>
                </div>
                <div className="flex flex-row items-center">
                  <IoCalendarOutline size={24} className='text-black' />
                  <p className='pl-2 text-gray-800 font-light'>{courseDates[0].kurs2}</p>
                </div>
              </div>
            </div>

            <button
              onClick={handleBooking}
              className="w-full py-2 px-4 rounded bg-black text-white"
            >
              Kursanfrage
            </button>
            <h4 className='pt-4 text-sm text-gray-600 font-normal'>Jetzt Platz anfragen</h4>
            <div className="mt-2 text-sm font-light text-gray-600">
                  Bitte nutzen Sie unser Formular, um einen Platz für den gewünschten Kurs und Zeitraum anzufragen. Wir werden uns per E-Mail mit Ihnen in Verbindung setzen 
                  und Ihnen mitteilen, ob noch ein Platz frei ist.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kurs_1;
