const faqData = [
    {
      question: "Was ist das Projekt KinderCampus?",
      answer:
        "Der KinderCampus ist ein Angebot des Fachbereichs Informatik der Hochschule Darmstadt, um  technikinteressierten Kindern und Jugendlichen programmierspezifische Sachverhalte im Rahmen von Lego Mindstorms zu vermitteln. Es versteht sich daher als eine Initiative zur MINT-Förderung.",
      open: false,
    },
    {
      question: "Was ist EV3 bzw. Robot Inventor?",
      answer: "Das EV3-Set gehört der MINDSTORMS-Reihe von LEGO an (3. Generation). Es wurde 2013 von Lego auf den Markt gebracht. Das Set besteht unter anderem aus drei interaktiven Servomotoren mit integrierten Rotationssensoren sowie einem Ultraschallsensor, einem Farb-/Lichtsensor, einem Gyrosensor und zwei Berührungssensoren. Es ist eine Weiterentwicklung der technisch bewährten NXT-Reihe. Weitere Infos: siehe https://education.lego.com/en-us/product-resources/mindstorms-ev3/downloads/whats-in-the-box. Seit Oktober 2020 gib es ein neues Lego Mindstorms-Set mit dem Namen Robot Inventor. Dieses Set enthält 4 Motoren, einen Farbsensor sowie einen Ultraschallsensor und verwendet die visuelle Blocksprache Scratch zur Steuerung.",
      open: false,
    },
    {
      question: "Wer kann an dem Lego KinderCampus teilnehmen?",
      answer: "Schülerinnen und Schüler mit Interesse an der Programmierung. Bei jedem Kurs ist angegeben, für welches Alter der Kurs geeignet ist. Sollten Beeinträchtigungen vorliegen, dann nehmen Sie bitte vorab Kontakt mit dem KinderCampus-Team auf. Vielen Dank.",
      open: false,
    },
    {
      question: "Wann findet der Lego KinderCampus statt?",
      answer: "In der Regel finden die Kurse in den letzten zwei hessischen Sommerferienwochen statt. Die aktuellen Termine finden Sie auf dieser Seite bei den Kursbeschreibungen.",
      open: false,
    },
    {
      question: "Ist eine Anmeldung erforderlich?",
      answer: "Ja, eine rechtzeitige Anmeldung ist erforderlich, da die Anzahl der Plätze begrenzt ist. Eine Anmeldung zu einem Kurs ist in der Regel ab Januar/Februar eines Jahres für das aktuelle Jahr möglich.Die Platzvergabe erfolgt nach dem Prinzip First Come - First Serve (dt. Wer zuerst kommt, malt zuerst), d.h. wer sich zuerst anmeldet erhält auch einen Platz. Ein Platz ist erst mit der Zusendung des Fragebogens sowie einer positiven Rückantwort vom KinderCampus-Team garantiert. Sofern keine freien Plätze mehr vorhanden sind, wird die Anmeldung in einer Warteliste aufgenommen. ",
      open: false,
    },
    {
      question: "Kann ich mein Kind wieder abmelden?",
      answer: "Eine Abmeldung ist bis 4 Wochen vor Kursbeginn problemlos möglich. In diesem Fall wird die gesamte Kursgebühr zurückerstattet. Bei kurzfristiger Stornierung werden 25% der Kursgebühr fällig, der Restbetrag wird erstattet. Bitte schicken Sie eine E-Mail an fbi-kindercampus@gmx.de. ",
      open: false,
    },
    {
      question: "Wie viele Kinder können insgesamt teilnehmen?",
      answer: "Bei den Kursen ist die Teilnehmeranzahl auf maximal 16 beschränkt, um eine gute Betreuung zu gewährleisten. Sollten mehr Anmeldungen vorliegen, wird eine Warteliste geführt.",
      open: false,
    },
    {
      question: "Was kostet der Lego Roboter KinderCampus?",
      answer: "Die Wochenkurse kosten jeweils 200 Euro.",
      open: false,
    },
    {
      question: "Muss ich meine Verpflegung selbst mitbringen?",
      answer: "Ja, bitte geben Sie Ihrem Kind eine eigene Verpflegung für die Pausen mit. Für Getränke (Wasser und Apfelsaftschorle) ist gesorgt. Eigene Getränke sind natürlich auch erlaubt.",
      open: false,
    },
    {
      question: "Was sollte ich mitbringen?",
      answer: "Wir stellen alle nötigen Arbeitsmittel (Mindstorms-Kasten, evtl. Ergänzungsset und Untergründe)  zur Verfügung. Nur das Essen/Mittagessen/Pausen-Snacks sollte man selbst mitbringen. Wenn man ein Mindstorms-Set zu Hause hat, dann kann man die erstellten Programme auch gerne auf einen eigenen USB-Stick kopieren. Auch darf man sein Handy/Smartphone mitnehmen, um die Lego-Modelle aufzunehmen und seinen Eltern zu zeigen. Eigene Bauteile von zu Hause dürfen aber in den Kursen nicht verwendet werden.",
      open: false,
    },
    {
      question: "Gibt es reine Mädchen- und Jungengruppen?",
      answer: "Es gibt keine reinen Mädchen- oder Jungengruppen. Die Kurse sind geschlechterneutral und integrativ.",
      open: false,
    },
    {
      question: "Muss ich schon programmieren können?",
      answer: "Nein, alles was erforderlich ist wird in den Kursen vermittelt. Es fängt mit kleinen Schritten an. Ziel ist, die wesentlichen Programmierkonzepte kennenzulernen. Beim Einteilen der Gruppen wird auch auf die Vorkenntnisse der Teilnehmer geachtet und der Einstieg in das Bauen und Programmieren erfolgt in unterschiedlichem Tempo.  Bei dem Expertenkurs sind Programmierkenntnisse vorteilhaft aber keine Vorbedingung. Im Expertenkurs ist das Lerntempo wesentlich höher als in den Grundkursen. Es werden zunächst die Grundlagen von Python vermittelt und dann dieses Wissen durch Bauen und Steuern der Lego-Modelle angewendet und ausgebaut.",
      open: false,
    },
  ];
  
  const testimonials = [
    {
      name: "Helena",
      text: "Ich fand den Kurs toll. Ich finde es aber doof, dass es so kurz war.",
    },
    {
      name: "Marc",
      text: "Ich fand es hier sehr cool und mir hat jede Maschine sehr gefallen. Jeder Betreuer war sehr nett. Es hat mir sehr viel Spaß gemacht.",
    },
    {
      name: "Lars",
      text: "Ich fand es sehr gut und spannend, weil wir viele Modelle gebaut und programmiert haben.",
    },
    
  ];

export const courseDates = [
    {
      id: 1,
      kurs1: "04.08.2025 - 08.08.2025",
      kurs2: "11.08.2025 - 15.08.2025",
      preis: "200"
    },
    {
      id: 2,
      kurs1: "04.08.2025 - 08.08.2025",
      kurs2: "11.08.2025 - 15.08.2025",
      preis: "200"
    },
  ];

export default {faqData, testimonials};
  


  