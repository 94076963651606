import React from 'react';
import { useNavigate } from 'react-router-dom';
import hero23 from '../assets/images/hero23.webp';

const Hero = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/#kurse');
  };

  return (
    <section id='start' className='pt-48 md:pt-48 md:pb-24 md:max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between px-4 md:px-8'>
      {/* Text on the left */}
      <div className='flex-1'>
        <h1 className='text-4xl md:text-5xl font-bold text-center md:text-left leading-tight'>
          Willkommen beim KinderCampus
        </h1>
        <p className='text-md md:text-base max-w-md font-light  mt-4 md:mt-6 text-center md:text-left'>
          Mit Lego Mindstorms® bringen wir Kinder spielerisch näher an die Welt der Informatik.
          Erste Erfolge in der Programmierung werden in unseren Kursen durch das Bauen verschiedener Lego-Modelle erreicht.
        </p>
        <div className='flex justify-center md:justify-start mt-6'>
        <a href='#kurse'>
        <button
            onClick={handleClick}
            className='px-6 py-3 md:px-8 md:py-4 text-sm md:text-md text-white bg-[#003566] rounded-md hover:bg-bg-cyan-200/50'
          >
            Jetzt anfragen
          </button>
        </a>
         
        </div>
      </div>
      {/* Image on the right */}
      <div className='flex-1 mt-8 md:mt-0 md:pl-8'>
        <img
          src={hero23}
          alt='Hero Image'
          className='w-full h-auto md:rounded-lg hidden md:block'
        />
      </div>
    </section>
  );
};

export default Hero;
