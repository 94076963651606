import React from 'react';
import { CiCalendar } from 'react-icons/ci';
import { PiStudent } from 'react-icons/pi';
import gruppenbild from '../assets/images/gruppenbild.webp';
import d14 from '../assets/images/d14.webp';

const CampaignSection = ({ image, title, description, students, years }) => (
  <div className='flex flex-col space-y-6'>
    <img src={image} alt='' className='rounded-lg w-full max-w-md h-48' />
    <div className='flex flex-col justify-center'>
      <div className='flex flex-col justify-between'>
        <h3 className='font-bold text-lg'>{title}</h3>
        <h3 className='font-light text-md'>{description}</h3>
      </div>
      <div className='flex flex-row justify-between '>
        <div className='flex flex-row space-x-2 space-y-4'>
          <PiStudent color='blue'  className='h-14  ' />
          <p className='text-sm text-blue-900 font-light '>{students}</p>
        </div>
        <div className='flex flex-row space-x-2 mx-2 space-y-4 '>
          <CiCalendar color='blue' size={20} className='h-14' />
          <h4 className='text-sm text-blue-900 font-light'>{years}</h4>
        </div>
      </div>
    </div>
  </div>
);

const Features = () => {
  return (
    <section id='überuns' className=' bg-[#edede9] pb-12 pt-8 md:pt-32'>
      <div className='md:max-w-7xl  container mx-auto px-4 md:px-8 flex flex-col md:flex-row justify-between space-y-8 md:space-y-0'>
        {/* Left Section */}
        <div className='order-2 md:order-1 px-10 py-10 md:py-0 md:px-0 flex flex-col md:space-x-4 md:flex-row w-full md:w-[60%] space-y-8 md:space-y-0'>
      
          <CampaignSection
            image={d14}
            title='Fachbereich Informatik'
            description='Der Fachbereich Informatik der Hochschule Darmstadt gehört mit einem engagierten Team zu den größten Informatikbereichen an deutschen Hochschulen.'
            students='2000 Studierende'
            years='seit 1971'
          />
              <CampaignSection
            image={gruppenbild         }
            title='Studierendenprojekt'
            description='Mit der Hilfe von 15 Bachelor-Studierenden wird das Projekt KinderCampus seit mehr als 20 Jahren aufgebaut und geleitet.'
            students='15 Studierende'
            years='seit 20 Jahren'
          />
        </div>

        {/* Right Section */}
        <div className='order-1 md:order-2 flex flex-col w-full md:w-[40%]'>
          <div className='max-w-lg text-3xl text-center md:text-left md:pl-8 space-y-4'>
            <h2 className='text-4xl md:text-[40px] font-bold text-center md:text-left '>Über den KinderCampus</h2>
         
          <p className='max-w-md mx-auto text-lg text-center md:text-left text-gray-700 '>
            Der KinderCampus des Fachbereichs Informatik der Hochschule Darmstadt vermittelt technikinteressierten Kindern und Jugendlichen im Alter von 10 bis 16 Jahren 
            programmierspezifische Kenntnisse mit Lego Mindstorms. Das Projekt zur MINT-Förderung findet in den letzten zwei Wochen der hessischen Sommerferien statt und 
            wird von Prof. Dr. Frank Bühler geleitet. Weitere Informationen gibt es bei <a href='https://www.echo-online.de/lokales/darmstadt/roboter-bauen-an-der-hochschule-darmstadt-1673427' className='text-blue-700'>echo-online</a>.
          </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
