import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


const Datenschutz= ()=>  {

    
  return (
    <div>
    
            <Navbar />


    <div className="p-6 md:pt-40 bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Datenschutzhinweis</h1>
      <p className="mb-4">
        Für Prof. Dr. Frank Bühler, Schoefferstrasse 8b, 64295 Darmstadt, hat der Schutz Ihrer persönlichen Informationen oberste Priorität. Selbstverständlich halten wir die relevanten Datenschutzgesetze ein und möchten Sie mit der nachfolgenden Datenschutzerklärung umfassend über den Umgang mit Ihren Daten aufklären.
      </p>
      <h2 className="text-3xl font-semibold mb-2">1. Datenschutz auf einen Blick</h2>
      <h3 className="text-2xl font-semibold mb-2">Allgemeine Hinweise</h3>
      <p className="mb-4">
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
      </p>
      <h3 className="text-2xl font-semibold mb-2">Datenerfassung auf dieser Website</h3>
      <h4 className="text-xl font-semibold mb-2">Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <p className="mb-4">
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt "Hinweis zur Verantwortlichen Stelle" in dieser Datenschutzerklärung entnehmen.
      </p>
      <h4 className="text-xl font-semibold mb-2">Wie erfassen wir Ihre Daten?</h4>
      <p className="mb-4">
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
      </p>
      <h4 className="text-xl font-semibold mb-2">Wofür nutzen wir Ihre Daten?</h4>
      <p className="mb-4">
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
      </p>
      <h4 className="text-xl font-semibold mb-2">Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
      <p className="mb-4">
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
      </p>
      <h4 className="text-xl font-semibold mb-2">Analyse-Tools und Tools von Drittanbietern</h4>
      <p className="mb-4">
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen. Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
      </p>
      <h2 className="text-3xl font-semibold mb-2">2. Hosting</h2>
      <h3 className="text-2xl font-semibold mb-2">Externes Hosting</h3>
      <p className="mb-4">
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
      </p>
      <p className="mb-4">
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z.B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p className="mb-4">
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen. Wir setzen folgenden Hoster ein:
      </p>
      <p className="mb-4">
        netcup GmbH<br/>
        Daimlerstraße 25, D-76185 Karlsruhe
      </p>
      <p className="mb-4">
        <strong>Auftragsverarbeitung</strong>
      </p>
      <p className="mb-4">
        Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
      </p>
      <h2 className="text-3xl font-semibold mb-2">3. Allgemeine Hinweise und Pflicht-Informationen</h2>
      <h3 className="text-2xl font-semibold mb-2">Datenschutz</h3>
      <p className="mb-4">
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </p>
      <h4 className="text-xl font-semibold mb-2">Hinweis zur verantwortlichen Stelle</h4>
      <p className="mb-4">
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
      </p>
      <p className="mb-4">
        Prof. Dr. Frank Bühler<br/>
        Schoefferstrasse 8b, 64295 Darmstadt<br/>
        E-Mail: fbi-kindercampus@gmx.de
      </p>
      <p className="mb-4">
        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
      </p>
      <h4 className="text-xl font-semibold mb-2">Speicherdauer</h4>
      <p className="mb-4">
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
      </p>
      <h4 className="text-xl font-semibold mb-2">Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h4>
      <p className="mb-4">
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z.B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.
        </p>
        <p className="mb-4">
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </p>
        <p className="mb-4">
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
        </p>
        <p className="mb-4">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </p>
        <p className="mb-4">
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p className="mb-4">
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
        </p>
        <p className="mb-4">
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>
        <p className="mb-4">
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
        </p>
        <p className="mb-4">
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </p>
        <p className="mb-4">
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <p className="mb-4">
          Recht auf Datenübertragbarkeit
        </p>
        <p className="mb-4">
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <p className="mb-4">
          SSL- bzw. TLS-Verschlüsselung
        </p>
        <p className="mb-4">
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p className="mb-4">
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <section className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Auskunft, Löschung und Berichtigung</h3>
                    <p className="text-gray-700 mb-4">
                        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
                    </p>
                </section>

                {/* Abschnitt Einschränkung der Verarbeitung */}
                <section className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Einschränkung der Verarbeitung</h3>
                    <p className="text-gray-700 mb-4">
                        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht unter anderem in den folgenden Fällen:
                    </p>
                    <ul className="list-disc list-inside text-gray-700 mb-4">
                        <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir Zeit, um dies zu überprüfen. Während dieser Prüfung können Sie die Einschränkung der Verarbeitung Ihrer Daten verlangen.</li>
                        <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
                        <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie diese jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung zu verlangen.</li>
                        <li>Wenn Sie Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten eingelegt haben, wird eine Abwägung zwischen Ihren und unseren Interessen durchgeführt. Solange nicht feststeht, wessen Interessen überwiegen, können Sie die Einschränkung der Verarbeitung Ihrer Daten verlangen.</li>
                    </ul>
                    <p className="text-gray-700 mb-4">
                        Wenn die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt wurde, dürfen diese Daten - von ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
                    </p>
                </section>

                {/* Abschnitt Widerspruch gegen Werbe-E-Mails */}
                <section className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Widerspruch gegen Werbe-E-Mails</h3>
                    <p className="text-gray-700 mb-4">
                        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, behalten wir uns ausdrücklich vor.
                    </p>
                </section>

                {/* Abschnitt Datenerfassung auf dieser Website */}
                <section className="mb-8">
                    <h3 className="text-xl font-semibold mb-2">Datenerfassung auf dieser Website</h3>
                    
                    {/* Unterabschnitt Cookies */}
                    <div className="mb-4">
                        <h4 className="text-lg font-semibold mb-2">Cookies</h4>
                        <p className="text-gray-700 mb-2">
                            Unsere Internetseiten verwenden so genannte "Cookies". Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
                        </p>
                        <p className="text-gray-700 mb-2">
                            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. für die Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Mehr Informationen darüber, was Cookies sind und welche Arten von Cookies wir speichern, erfahren Sie in unserer Cookie-Richtlinie. Des Weiteren können Sie jederzeit über die Cookie-Einstellungen auswählen, welche Arten von Cookies wir bei Ihnen verwenden dürfen.
                        </p>
                    </div>
                    </section>
                    {/* Unterabschnitt Server-Log-Dateien */}
                    <p className="text-gray-700 mb-2">
                            Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dazu gehören:
                        </p>
                        <ul className="list-disc list-inside text-gray-700 mb-2">
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                        <p className="text-gray-700 mb-2">
                            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website - hierzu müssen die Server-Log-Files erfasst werden.
                        </p>


 {/* Unterabschnitt Kontaktformular */}
 <div className="mb-4">
                        <h4 className="text-lg font-semibold mb-2">Kontaktformular</h4>
                        <p className="text-gray-700 mb-2">
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese abgefragt wurde.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.
                        </p>
                    </div>
                    
                    {/* Unterabschnitt Anfrage per E-Mail, Telefon oder Telefax */}
                    <div className="mb-8">
                        <h4 className="text-lg font-semibold mb-2">Anfrage per E-Mail, Telefon oder Telefax</h4>
                        <p className="text-gray-700 mb-2">
                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese abgefragt wurde.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen - insbesondere gesetzliche Aufbewahrungsfristen - bleiben unberührt.
                        </p>
                    </div>

                    {/* Abschnitt Plugins und Tools */}
                    <section>
                        <h3 className="text-xl font-semibold mb-2">Plugins und Tools</h3>
                        
                        {/* Unterabschnitt Font Awesome */}
                        <div className="mb-4">
                            <h4 className="text-lg font-semibold mb-2">Font Awesome (lokales Hosting)</h4>
                            <p className="text-gray-700 mb-2">
                                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font Awesome. Font Awesome ist lokal installiert. Eine Verbindung zu Servern von Fonticons, Inc. findet dabei nicht statt.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Weitere Informationen zu Font Awesome finden Sie in der Datenschutzerklärung für Font Awesome unter: <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://fontawesome.com/privacy</a>.
                            </p>
                        </div>
                        
                        {/* Unterabschnitt Google Analytics */}
                        <div className="mb-4">
                            <h4 className="text-lg font-semibold mb-2">Google Analytics</h4>
                            <p className="text-gray-700 mb-2">
                                Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f DSGVO) Google Analytics, einen Webanalysedienst der Google Ireland Limited ("Google") ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>).
                            </p>
                            <p className="text-gray-700 mb-2">
                                Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten Googles und seiner Partner geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln (sog. "Remarketing-", bzw. "Google-Analytics-Audiences"). Mit Hilfe der Remarketing Audiences möchten wir auch sicherstellen, dass unsere Anzeigen dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmef
</p>

                                <p className="text-gray-700 mb-2">
                                llenen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google (<a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://adssettings.google.com/authenticated</a>).
                            </p>
                        </div>
                        
                        {/* Unterabschnitt Google Maps */}
                        <div className="mb-8">
                            <h4 className="text-lg font-semibold mb-2">Google Maps</h4>
                            <p className="text-gray-700 mb-2">
                                Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Irland.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://policies.google.com/privacy</a>.
                            </p>
                        </div>
                    </section>

                    {/* Abschnitt Änderung unserer Datenschutzbestimmungen */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Änderung unserer Datenschutzbestimmungen</h3>
                        <p className="text-gray-700 mb-2">
                            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
                        </p>
                    </section>

                    {/* Abschnitt Fragen an den Datenschutzbeauftragten */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Fragen an den Datenschutzbeauftragten</h3>
                        <p className="text-gray-700 mb-2">
                            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:
                        </p>
                        <p className="text-gray-700 mb-2">
                            <strong>Name des Verantwortlichen:</strong> [Name]
                        </p>
                        <p className="text-gray-700 mb-2">
                            <strong>E-Mail:</strong> [E-Mail-Adresse]
                        </p>
                    </section>

                    {/* Abschnitt Widerruf Ihrer Einwilligung zur Datenverarbeitung */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                        <p className="text-gray-700 mb-2">
                            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                        </p>
                    </section>

                    {/* Abschnitt Beschwerderecht bei der zuständigen Aufsichtsbehörde */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
                        <p className="text-gray-700 mb-2">
                            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                        </p>
                    </section>

                    {/* Abschnitt SSL- bzw. TLS-Verschlüsselung */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">SSL- bzw. TLS-Verschlüsselung</h3>
                        <p className="text-gray-700 mb-2">
                            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                        </p>
                    </section>

                    {/* Abschnitt Auskunft, Löschung und Berichtigung */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Auskunft, Löschung und Berichtigung</h3>
                        <p className="text-gray-700 mb-2">
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
                        </p>
                    </section>

                    {/* Abschnitt Widerspruch gegen Werbe-E-Mails */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Widerspruch gegen Werbe-E-Mails</h3>
                        <p className="text-gray-700 mb-2">Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien</p>
                        <p className="text-gray-700 mb-2">
                            wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                        </p>
                    </section>

                    {/* Abschnitt Datensicherheit */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Datensicherheit</h3>
                        <p className="text-gray-700 mb-2">
                            Wir treffen technische und organisatorische Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten gegen unbeabsichtigte oder unrechtmäßige Löschung, Veränderung oder Verlust und gegen unberechtigte Weitergabe oder unberechtigten Zugriff zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
                        </p>
                    </section>

                    {/* Abschnitt Server-Log-Dateien */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Server-Log-Dateien</h3>
                        <p className="text-gray-700 mb-2">
                            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                        </p>
                        <ul className="list-disc list-inside text-gray-700 mb-2">
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>IP-Adresse</li>
                        </ul>
                        <p className="text-gray-700 mb-2">
                            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                        </p>
                    </section>

                    {/* Abschnitt Kontaktformular */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Kontaktformular</h3>
                        <p className="text-gray-700 mb-2">
                            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese abgefragt wurde.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben unberührt.
                        </p>
                    </section>

                    {/* Abschnitt Anfrage per E-Mail, Telefon oder Telefax */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Anfrage per E-Mail, Telefon oder Telefax</h3>
                        <p className="text-gray-700 mb-2">
                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO), sofern diese abgefragt wurde.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen - insbesondere gesetzliche Aufbewahrungsfristen - bleiben unberührt.
                        </p>
                    </section>

                    {/* Abschnitt Plugins und Tools */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Plugins und Tools</h3>
                        
                        {/* Unterabschnitt Font Awesome */}
                        <div className="mb-4">
                            <h4 className="text-lg font-semibold mb-2">Font Awesome (lokales Hosting)</h4>
                            <p className="text-gray-700 mb-2">
                                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font Awesome. Font Awesome ist lokal installiert. Eine Verbindung zu Servern von Fonticons, Inc. findet dabei nicht statt.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Weitere Informationen zu Font Awesome finden Sie in der Datenschutzerklärung für Font Awesome unter: <a href="https://fontawesome.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://fontawesome.com/privacy</a>.
                            </p>
                        </div>
                        
                        {/* Unterabschnitt Google Analytics */}
                        <div className="mb-4">
                            <h4 className="text-lg font-semibold mb-2">Google Analytics</h4>
                            <p className="text-gray-700 mb-2">
                                Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f DSGVO) Google Analytics, einen Webanalysedienst der Google Ireland Limited ("Google") ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>).
                            </p>
                            <p className="text-gray-700 mb-2">
                                Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten Googles und seiner Partner geschalteten Anzeigen, nur solchen Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln (sog. "Remark eting-Listen"). Mit Hilfe der Remarketing-Listen möchten wir auch sicherstellen, dass unsere Anzeigen dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Google Analytics verwendet Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website wie
                                <ul className="list-disc list-inside text-gray-700 mb-2">
                                    <li>Besuchte Webseiten</li>
                                    <li>Verweildauer auf der Webseite</li>
                                    <li>Art des verwendeten Browsers</li>
                                    <li>Verwendetes Betriebssystem</li>
                                    <li>IP-Adresse</li>
                                    <li>Herkunft des Besuchers (Land, Stadt)</li>
                                </ul>
                                werden an einen Server von Google in den USA übertragen und dort gespeichert. Die IP-Adresse wird jedoch vor der Speicherung anonymisiert, sodass keine persönlichen Informationen an Google übertragen werden können.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Die Nutzung von Google Analytics erfolgt im Interesse einer bedarfsgerechten Gestaltung und der Optimierung unserer Webseite. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://policies.google.com/privacy</a>).
                            </p>
                        </div>

                        {/* Unterabschnitt Google Fonts */}
                        <div className="mb-4">
                            <h4 className="text-lg font-semibold mb-2">Google Fonts</h4>
                            <p className="text-gray-700 mb-2">
                                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Weitere Informationen zu Google Fonts finden Sie unter <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://developers.google.com/fonts/faq</a> und in der Datenschutzerklärung von Google: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://policies.google.com/privacy</a>.
                            </p>
                        </div>

                        {/* Unterabschnitt Google Maps */}
                        <div className="mb-8">
                            <h4 className="text-lg font-semibold mb-2">Google Maps</h4>
                            <p className="text-gray-700 mb-2">
                                Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Irland.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                            </p>
                            <p className="text-gray-700 mb-2">
                                Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://policies.google.com/privacy</a>.
                            </p>
                        </div>
                    </section>

                    {/* Abschnitt Änderung unserer Datenschutzbestimmungen */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Änderung unserer Datenschutzbestimmungen</h3>
                        <p className="text-gray-700 mb-2">
                            Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
                        </p>
                    </section>

                    {/* Abschnitt Fragen an den Datenschutzbeauftragten */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Fragen an den Datenschutzbeauftragten</h3>
                        <p className="text-gray-700 mb-2">
                            Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:
                        </p>
                        <p className="text-gray-700 mb-2">
                            <strong>Name des Verantwortlichen:</strong> [Name]
                        </p>
                        <p className="text-gray-700 mb-2">
                            <strong>E-Mail:</strong> [E-Mail-Adresse]
                        </p>
                    </section>

                    {/* Abschnitt Widerruf Ihrer Einwilligung zur Datenverarbeitung */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                        <p className="text-gray-700 mb-2">
                            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                        </p>
                    </section>

                    {/* Abschnitt Beschwerderecht bei der zuständigen Aufsichtsbehörde */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
                        <p className="text-gray-700 mb-2">Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutz</p>
                        <p className="text-gray-700 mb-2">
                            utzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                        </p>
                    </section>

                    {/* Abschnitt Recht auf Datenübertragbarkeit */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Recht auf Datenübertragbarkeit</h3>
                        <p className="text-gray-700 mb-2">
                            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                        </p>
                    </section>

                    {/* Abschnitt SSL- bzw. TLS-Verschlüsselung */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">SSL- bzw. TLS-Verschlüsselung</h3>
                        <p className="text-gray-700 mb-2">
                            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                        </p>
                        <p className="text-gray-700 mb-2">
                            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                        </p>
                    </section>

                    {/* Abschnitt Auskunft, Sperrung, Löschung */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Auskunft, Sperrung, Löschung</h3>
                        <p className="text-gray-700 mb-2">
                            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                        </p>
                    </section>

                    {/* Abschnitt Widerspruch gegen Werbe-E-Mails */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Widerspruch gegen Werbe-E-Mails</h3>
                        <p className="text-gray-700 mb-2">
                            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                        </p>
                    </section>

                    {/* Abschnitt Gültigkeit der Datenschutzerklärung */}
                    <section className="mb-8">
                        <h3 className="text-xl font-semibold mb-2">Gültigkeit der Datenschutzerklärung</h3>
                        <p className="text-gray-700 mb-2">
                            Diese Datenschutzerklärung ist aktuell gültig und hat den Stand [Datum]. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter [Link zur Datenschutzerklärung] von Ihnen abgerufen und ausgedruckt werden.
                        </p>
                    </section>

                    </div>
                    
                   
                    <Footer />           

</div>

  );
}

export default Datenschutz;
