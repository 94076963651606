import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Kurs_1 from '../components/kurs_1';


const Page_4= ()=>  {

    
  return (
    <div>
    
            <Navbar />
            <div className='max-w-xl m-4 md:max-w-7xl md:mx-auto  pb-32'>
                <Kurs_1 />
                
            </div>
            <Footer />
           
    </div>
  );
}

export default Page_4;
