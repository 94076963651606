import {React, useEffect} from "react";
import { Link } from 'react-router-dom';
import { FaCheck } from "react-icons/fa6";
import { FaInfoCircle } from "react-icons/fa";
import img3 from '../assets/images/course1.webp';

import expertenkurs from '../assets/images/expert.webp';

import { BsTicketPerforated } from "react-icons/bs";

const Courses = () => {


    return(
        <div id="kurse" className="">
        <div  className="p-4 mt-6 pb-8 pt-28  mx-auto max-w-screen-lg">
                    <p  className="text-blue-400 text-md text-center">KURSE</p>
                    <h2 className='text-4xl font-bold text-center'>
                        Was wir anbieten?
                    </h2>
                    <p className="mx-auto max-w-lg text-gray-500 text-center pt-6 font-light ">
                    Unsere Kurse bieten Kindern und Jugendlichen die Möglichkeit, ihr Interesse an Technik und Informatik weiterzuentwickeln.  
                    Die Kurse finden in den letzten beiden hessischen Sommerferienwochen statt. 
                </p>
                   
                   <div className="md:p-2 flex flex-col space-y-14 md:space-x-10 md:space-y-0  md:flex-row   mt-16">
                 
                   <div class="relative flex  mx-auto  md:w-[50%]  flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md ">
            <div class="relative  mx-4 -mt-6 md:mx-0 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
            <img src={img3} alt="" />

            </div>
            <div class="p-6">
                <h5 class="mb-2 block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                Grundkurs mit Scratch
                </h5>
                <p class="block font-sans text-base font-light leading-relaxed text-inherit antialiased">
                  Dieser Kurs richtet sich an 10- bis 14-Jährige, die Spaß am Bauen und Programmieren von Lego-Modellen haben.
                  Es werden mehrere Lego-Modelle (mit Mindstorms EV3-Set oder Robot Inventor Set) gebaut und mit 
                  Scratch-Programmen gesteuert. Je nach Vorkenntnissen können
                  auch komplexere Programme entwickelt werden.     
               </p>
<br/>
<hr className="h-[2px] w-full bg-gray-200 mt-2" />

<div className="text-gray-600 pt-4 text-md font-light space-y-4 ">

                        <div className="flex felx-col ">
                                <FaCheck size={20} color="green"/>
                                <p className="pl-2"> Keine Programmierkenntnisse erforderlich</p>
                            </div>
                            <div className="flex felx-col ">
                                <FaCheck size={20} color="green" />
                                <p className="pl-2">Individuelle, studentische Betreuung</p>
                            </div>
                            <div className="flex felx-col">
                                <FaCheck size={20} color="green" className="" />
                                <p className="pl-2">Spielerischer Einstieg in die Programmierung</p>
                            </div>
                    </div>

            </div>
            <div class="mx-auto p-6 pt-10">
                <div className="flex flex-row space-x-2">
                <Link to="/kurs_1"  target="_blank"> 
                <button data-ripple-light="true" type="button" className="flex flex-row  select-none rounded-lg bg-[#003566] py-3 px-3 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
                <p className="text-[14px] md:text-[18px] pt-[4px] font-bold"> Erfahre mehr</p> 
                <FaInfoCircle size={24} className="  mx-2"/>

                </button>
                </Link>

                <Link to="/kurs_1"  target="_blank">
                <button data-ripple-light="true" type="button" className="flex flex-row  select-none rounded-lg bg-green-900 py-3 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
                <p className="text-[14px] md:text-[18px] pt-[4px] font-bold"> Buchen</p> 
                <BsTicketPerforated  size={24} className="  mx-2"/>

                </button>
                </Link>

                </div>
            </div>
            </div> 

            <div class="relative flex  mx-auto  md:w-[50%]  flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md ">
            <div class="relative  mx-4 -mt-6 md:mx-0 h-40 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
            <img src={expertenkurs} alt="" />

            </div>
            <div class="p-6">
                <h5 class="mb-2 block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                Expertenkurs mit Python
                </h5>
                <p class="block font-sans text-base font-light leading-relaxed text-inherit antialiased">
                  Der Schwerpunkt dieses Kurses liegt auf der Programmierung in Python. Nach einer Einführung in die Programmiersprache Python werden 
                  mehrere Modelle mit dem Robot Inventor Set aufgebaut und mit Python gesteuert.             
                </p>
<br/> <br/>
<hr className="h-[2px] w-full bg-gray-200 mt-8" />
  
<div className="text-gray-600 pt-4 text-md font-light space-y-2 ">

                        <div className="flex felx-col ">
                                <FaCheck size={32} color="green"/>
                                <p className="pl-2"> Grundlegende Programmierkenntnisse in Scratch oder einer anderen 
                                                     Programmiersprache erforderlich</p>
                            </div>
                            <div className="flex felx-col">
                                <FaCheck size={20} color="green" className="" />
                                <p className="pl-2"> Allgemeine Kenntnisse im Umgang mit einem PC erforderlich</p>
                            </div>
                    </div>

            </div>
            <div class="mx-auto p-6 pt-4">
                <div className="flex flex-row space-x-2">
                 <Link to="/kurs_2"  target="_blank">

                <button data-ripple-light="true" type="button" className="flex flex-row  select-none rounded-lg bg-[#003566] py-3 px-3 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
                <p className="text-[14px] md:text-[18px] pt-[4px] font-bold"> Erfahre mehr</p> 
                <FaInfoCircle size={24} className="  mx-2"/>

                </button>
                </Link>
                <Link to="/kurs_2"  target="_blank">
                <button data-ripple-light="true" type="button" className="flex flex-row  select-none rounded-lg bg-green-900 py-3 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none">
                <p className="text-[14px] md:text-[18px] pt-[4px] font-bold"> Buchen</p> 
                <BsTicketPerforated  size={24} className="  mx-2"/>

                </button>
                </Link>

                </div>
            </div>
            </div>  
                   </div>
    </div>
    </div>
    );
    
}

export default Courses; 