import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import kindercampus from '../assets/images/Kindercampus_Logo.webp';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/#start');
  };

  const handleClickueberuns = () => {
    navigate('/#ueberuns');
  };

  const handleClickCourses = () => {
    navigate('/#kurse');
  };

  const handleClickFAQ = () => {
    navigate('/#faq');
  };

  const handleClickRez = () => {
    navigate('/#rezensionen');
  };

  const handleClickContact = () => {
    navigate('/#kontakt');
  };

  return (
    <nav className='fixed top-0 left-0 w-full z-50 bg-white'>
      <div className='container p-4 mx-auto md:px-4 py-2'>
        {/* Flex Container */}
        <div className='flex justify-between items-center'>
          {/* Logo */}
          <div className='pt-2'>
            <img
              onClick={handleClick}
              src={kindercampus}
              alt='Company Logo'
              className='w-40 h-20 cursor-pointer hover:bg-cyan-300 hover:rounded-xl'
            />
          </div>
          {/* Menu Items */}
          <div className='hidden space-x-6 md:flex'>
            <a onClick={handleClick} href='#start' className='hover:text-darkGrayishBlue'>
              Start
            </a>
            <a onClick={handleClickueberuns} href='#ueberuns' className='hover:text-darkGrayishBlue'>
              Über Uns
            </a>
            <a onClick={handleClickCourses} href='#kurse' className='hover:text-darkGrayishBlue'>
              Kurse
            </a>
            <a onClick={handleClickFAQ} href='#FAQ' className='hover:text-darkGrayishBlue'>
              FAQ
            </a>
            <a onClick={handleClickRez} href='#rezensionen' className='hover:text-darkGrayishBlue'>
              Rezensionen
            </a>
            <a onClick={handleClickContact} href='#kontakt' className='hover:text-darkGrayishBlue'>
              Kontakt
            </a>
          </div>
          {/* Hamburger Icon */}
          <button
            className={
              toggleMenu
                ? 'open block hamburger md:hidden focus:outline-none'
                : 'block hamburger md:hidden focus:outline-none'
            }
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <span className='hamburger-top'></span>
            <span className='hamburger-middle'></span>
            <span className='hamburger-bottom'></span>
          </button>
        </div>

        {/* Mobile Menu */}
        <div className='md:hidden'>
          <div
            className={
              toggleMenu
                ? 'absolute z-50 flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
                : 'hidden'
            }
          >
            <a
              href='#start'
              onClick={(e) => {
                handleClick();
                setToggleMenu(false);
              }}
            >
              Start
            </a>
            <a
              href='#ueberuns'
              onClick={(e) => {
                handleClickueberuns();
                setToggleMenu(false);
              }}
            >
              Über Uns
            </a>
            <a
              href='#kurse'
              onClick={(e) => {
                handleClickCourses();
                setToggleMenu(false);
              }}
            >
              Kurse
            </a>
            <a
              href='#FAQ'
              onClick={(e) => {
                handleClickFAQ();
                setToggleMenu(false);
              }}
            >
              FAQ
            </a>
            <a
              href='#rezensionen'
              onClick={(e) => {
                handleClickRez();
                setToggleMenu(false);
              }}
            >
              Rezensionen
            </a>
            <a
              href='#kontakt'
              onClick={(e) => {
                handleClickContact();
                setToggleMenu(false);
              }}
            >
              Kontakt
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
