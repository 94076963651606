import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Impressum from '../components/impressum';


const Page_6= ()=>  {

    
  return (
    <div>
    
            <Navbar />
            <div className='max-w-xl m-4 md:max-w-7xl md:mx-auto  '>
                <Impressum />
                
            </div>
            <Footer />
           
    </div>
  );
}

export default Page_6;
