import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


import kindercampus from '../assets/images/Kindercampus_Logo.webp';

const Footer = () => {


  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/#start');
  };

  const handleClickueberuns = () => {
    navigate('/#ueberuns');
  };

  const handleClickCourses = () => {
    navigate('/#kurse');
  };

  const handleClickFAQ = () => {
    navigate('/#faq');
  };

  const handleClickRez = () => {
    navigate('/#rezensionen');
  };

  const handleClickContact = () => {
    navigate('/#kontakt');
  };


  return (
    <div className='bg-gray-900'>
      {/* Flex Container */}
      <div className='container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0'>
        {/* Logo and social links container */}
        <div className='flex flex-col-reverse items-center justify-between space-y-12 md:flex-col md:space-y-0 md:items-start'>
          <div className='mx-auto my-6 text-center text-white md:hidden'>
            Copyright © 2024, All Rights Reserved by Frank Bühler
          </div>
          {/* Logo */}
          <div>
            <img onClick={handleClick} src={kindercampus} className='w-40 h-20 cursor-pointer hover:bg-cyan-300	hover:rounded-xl' alt='' />
          </div>
          {/* Social Links Container */}
         
        </div>
        {/* List Container */}
        <div className='flex justify-around space-x-32'>
          <div className='flex flex-col space-y-3 text-white'>
            <Link to='#' className='hover:text-brightRed'>
              Start
            </Link>
            <a onClick={handleClickueberuns} href='#ueberuns' to='#' className='hover:text-brightRed'>
              überuns
            </a>
            <a onClick={handleClickCourses} href='#kurse' className='hover:text-darkGrayishBlue'>
              Kurse
            </a>
            <a onClick={handleClickFAQ} href='#FAQ' className='hover:text-darkGrayishBlue'>
              FAQ
            </a>
            <a onClick={handleClickRez} href='#rezensionen' className='hover:text-darkGrayishBlue'>
              Rezensionen
            </a>
           
          </div>
          <div className='flex flex-col space-y-3 text-white'>
          <a onClick={handleClickContact} href='#kontakt' className='hover:text-darkGrayishBlue'>
              Kontakt
            </a>
            <Link to='/impressum' className='hover:text-brightRed'>
              Impressum
            </Link>
            <Link to='/datenschutz' className='hover:text-brightRed'>
              Datenschutz
            </Link>
          </div>
        </div>

        {/* Input Container */}
        <div className='flex flex-col justify-between'>
          <form>
            <div className='flex space-x-3'>
          
             
            </div>
          </form>
          <div className='hidden text-sm text-white md:block'>
              Copyright © 2024, All Rights Reserved by Frank Bühler

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
