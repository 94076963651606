import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


const Impressum= ()=>  {
    const navigate = useNavigate();

    const handleClickContact = () => {
        navigate('/#kontakt');
      };
    
  return (
    <div className=''>
    
    <div className="container pt-32 p-4 max-w-screen">
        <h1 className="text-4xl font-bold md:m-4">Impressum</h1>
         <div className='flex flex-col md:flex-row md:justify-between mt-10 '>
                    <div className="flex  justify-start max-w-3xl bg-gray-10 rounded-lg md:p-10">
                        <div><p>Angaben gemäß § 5 TMG</p><p>Prof. Dr. Frank Bühler <br/> 
                        Schoefferstrasse 8b<br/> 
                        64295 Darmstadt
                        <br/> 
                        </p><p> <strong>Vertreten durch: </strong><br/>
                        Prof. Dr. Frank Bühler<br/>
                        </p><p><strong>Kontakt:</strong> <br/>
                        Web: www.lernsys-informatik.de<br/>
                        E-Mail: <a href='fbi-kindercampus@gmx.de'>fbi-kindercampus@gmx.de</a><br/></p><br/> </div>
                    </div>
                <div className='p-2 flex flex-col justify-end	 md:max-w-3xl bg-gray-100 rounded-lg md:p-20'>
                    <p className='text-start md:text-end'>
                Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV<br/> 
                <p className='text-start md:text-end'>
                Prof. Dr. Frank Bühler<br/> 
                Hochschule Darmstadt<br/> 
                Schoefferstrasse 8b<br/> 
                64295 Darmstadt<br/> 
                </p>
                
                </p>
                <hr className="h-[2px] mt-2 max-w-screen bg-gray-200" />
                <p className='text-start'>
                Über unser    <a href='#kontakt' onClick={(e) => {
              handleClickContact();
            }}
            className='text-blue-600'>
              Kontakt-Formular
            </a> können Sie jederzeit mit uns in Kontakt treten. Wir werden Ihre Anfrage schnellstmöglich bearbeiten und freuen uns auf Ihr Feedback.
                </p>
       </div>
       </div>
      
       
    </div>
            <div className='mt-40 '>

            </div>
           
    </div>
  );
}

export default Impressum;
