import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Page_1 from './pages/page_1.jsx';

import Page_2 from './pages/page_2.jsx';
import Page_3 from './pages/page_3.jsx';
import Impressum from './components/impressum.jsx';
import Datenschutz from './components/datenschutz.jsx';
import Page_4 from './pages/page_4.jsx';
import Page_5 from './pages/page_5.jsx';
import Page_6 from './pages/page_6.jsx';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Page_1 />} />
        <Route path="/kurs_1" element={<Page_4 />} />   
        <Route path='/kurs_2' element={<Page_5 />} />
        <Route path='/buchung/' element={<Page_2 />} />
        <Route path='/buchun2/' element={<Page_3 />} />
        <Route path="/impressum" element={<Page_6 />} />   
        <Route path='/datenschutz' element={<Datenschutz />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
